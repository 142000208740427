<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline primary justify-center white--text">Whirelab</v-card-title>

        <v-card-text class="text-center justify-center">
          <!-- <pre>{{vacancy.locale}}</pre> -->
          <p class="pt-8" v-if="vacancy">
            {{$t('aplyJob', vacancy.locale)}}
            <strong>{{ vacancy.title }}</strong> {{$t('at',vacancy.locale)}}
            <strong>{{ vacancy.entity ? vacancy.entity.name : '' }}.</strong>
            <br />{{$t('faca',vacancy.locale)}}
            <a :href="`${appUrl}/login`">Login</a> {{$t('or',vacancy.locale)}}
            <a :href="`${appUrl}/candidate/register`">{{$t('signup',vacancy.locale)}}</a>
          </p>
          <p v-else class="pt-8">
            {{$t("star_session",locale)}}
            <br />
            <a :href="`${appUrl}/login`">Login</a> {{$t('or',locale)}}
            <a :href="`${appUrl}/candidate/register`">{{$t('signup',locale)}}</a>
          </p>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { APP_URL } from '../../../../../api'
export default {
  data() {
    return {
      appUrl: APP_URL,
      dialog: false,
      vacancy: {},
      locale :"pt"
    };
  },
  created() {
    //  this.$i18n.locale = this.vacancy.locale;
    // eslint-disable-next-line no-undef
    Fire.$on("openGuestApplyModal", vacancy => {
      this.dialog = true;
      this.vacancy = vacancy;
       this.locale = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "pt";
    });

  },
  
};
</script>