var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"elevation":hover ? 16 : 2}},[_c('v-card',{attrs:{"flat":""},on:{"click":function($event){return _vm.go()}}},[_c('v-system-bar',{staticClass:"px-0",attrs:{"dense":"","color":"white"}},[_c('v-spacer'),_c('v-btn',{staticClass:"px-5 text-lowercase",attrs:{"x-small":"","tile":"","color":_vm.status2(_vm.vacancy).color}},[_vm._v(" "+_vm._s(_vm.status2(_vm.vacancy).status === "Expirado" || _vm.status2(_vm.vacancy).status === "expired" ? _vm.status2(_vm.vacancy).status : _vm.status2(_vm.vacancy).status)+" ")])],1),_c('v-card-text',[_c('v-list-item',{staticClass:"d-flex flex-row justify-space-between align-center px-0 my-3"},[_c('v-list-item-avatar',{attrs:{"color":"grey","size":"50"}},[_c('img',{attrs:{"src":_vm.vacancy.entity.logo
                  ? `${_vm.apiUrl}/images/entity/${_vm.vacancy.entity.logo}`
                  : `/avatar-whire.png`,"alt":"John"}})])],1),_c('v-list-item',{staticClass:"d-flex flex-row justify-space-between align-center px-0 my-3"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 font-weight-medium pb-2"},[_vm._v(_vm._s(_vm.vacancy.title))]),_c('v-list-item-subtitle',[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("place")]),_vm._l((_vm.vacancy.place),function(item,index){return _c('span',{key:index,staticStyle:{"color":"#302F2F"}},[_vm._v(_vm._s(item)+", ")])})],2)],1)],1),_c('div',{staticStyle:{"height":"150px"},domProps:{"innerHTML":_vm._s(
            _vm.vacancy.description
              ? _vm.vacancy.description.substring(0, 140) + '...'
              : ''
          )}})],1)],1),_c('v-divider',{staticClass:"mx-4",attrs:{"color":"#000"}}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"}},[_c('div',[_vm._v(_vm._s(_vm.vacancy.type))])]),_c('v-spacer'),(!_vm.vacancy.private)?_c('v-btn',{staticClass:"mr-3",attrs:{"disabled":_vm.share,"icon":""},on:{"click":function($event){return _vm.openShareDialog()}}},[(!_vm.share)?_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-share-variant")]):_vm._e()],1):_vm._e()],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }